<template>
  <transition name="fade">
    <main role="main" v-if="itemLoading === false">
      <meta-title :title="metaTitle"></meta-title>
      <section class="section section-default section-album">
        <div class="container m-container-only-s">
          <div class="row">
            <div class="col col-poster col-10 col-s-24">
              <figure>
                <img :src="replaceCoverImage(contentData.cover)" alt="" />
              </figure>
            </div>
            <div class="col col-tracks col-14 col-s-24">
              <!--                <h1>Pink Floyd.</h1>-->
              <h2>
                <span
                  >{{ contentData.title }}
                  <a
                    href=""
                    style="color: red"
                    @click.prevent="toggleFavorites(contentData)"
                  >
                    <img
                      src="~@/assets/i/bookmark.svg"
                      :class="{
                        'opacity-40': !checkFavorites(contentData.id)
                      }"
                      alt=""
                    /> </a
                ></span>
              </h2>
              <a
                href="#"
                v-if="tracks.length"
                class="play-button"
                @click.prevent="
                  toggleActive({ position: tracks[0].position, status: true })
                "
                >{{ $t("album.playAlbum") }}</a
              >

              <transition-group name="audio-list" class="tracks" tag="div">
                <Track
                  :item="item"
                  v-for="(item, index) in tracks"
                  :key="item.position"
                  :active="playList[index].active"
                  :time="playList[index].currentTime"
                  :index="index"
                  @active-change="toggleActive"
                />
              </transition-group>
            </div>
          </div>
        </div>
      </section>
    </main>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import cover from "@/mixins/cover";
import Track from "@/components/audio/Track";
import utils from "@/lib/utils";
export default {
  name: "AlbumInfo",
  title() {
    return this.$t("pages.album") + this.contentData.title;
  },
  props: ["shuffle"],
  components: { Track },
  mixins: [cover],
  data() {
    return {
      repeat: false
    };
  },
  computed: {
    ...mapState("audio", {
      contentData: state => state.content,
      advertiseData: state => state.advertise,
      itemLoading: state => state.itemLoading,
      memory: state => state.memory,
      activePlayer: state => state.activePlayer
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapGetters("favorites", {
      checkFavorites: "CHECK_EXIST"
    }),
    tracks() {
      if (this.shuffle) {
        return utils.shuffle(this.contentData.contentItems);
      } else {
        return this.contentData.contentItems;
      }
    },
    activeTrack() {
      return this.contentData.contentItems[
        this.memory.findIndex(item => item.active)
      ];
    },
    activeMemory() {
      return this.memory.find(item => item.active);
    },
    playList() {
      let newArray = [];
      for (let i = 0; i < this.tracks.length; i++) {
        newArray.push(this.tracks[i]);
        let mm = this.memory.find(
          item => item.position === this.tracks[i].position
        );
        newArray[i].active = mm ? mm.active : false;
        newArray[i].currentTime = mm ? mm.currentTime : 0;
      }
      return newArray;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setEntityContent(["itemLoading", "empty"]);
    next();
  },
  watch: {
    itemLoading: {
      handler() {
        if (this.itemLoading === "empty") {
          this.actionItem({ id: this.$route.params.id });
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("audio", {
      actionItem: "item"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    ...mapMutations("audio", {
      setEntityContent: "SET_ENTITY"
    }),
    ...mapActions("favorites", {
      toggleFavorites: "toggleEntity"
    }),
    currentTimeSec(payload) {
      this.memory.find(item => item.position === payload.position).currentTime =
        payload.currentTime;
    },
    prev() {
      for (let i = 0; i < this.memory.length; i++) {
        if (this.memory[i].active) {
          this.memory[i].active = false;
          if (i !== 0) {
            this.memory[i - 1].active = true;
          } else {
            this.memory[this.memory.length - 1].active = true;
          }
          break;
        }
      }
    },
    next() {
      for (let i = 0; i < this.memory.length; i++) {
        if (this.memory[i].active) {
          this.memory[i].active = false;
          if (i === this.memory.length - 1) {
            this.memory[0].active = true;
          } else {
            this.memory[i + 1].active = true;
          }
          break;
        }
      }
    },
    toggleShuffle(payload) {
      this.shuffle = payload;
    },
    toggleRepeat(payload) {
      this.repeat = payload;
    },
    toggleActive(payload) {
      if (!this.memory.length) {
        for (let i = 0; i < this.contentData.contentItems.length; i++) {
          this.memory.push({
            active: false,
            position: this.contentData.contentItems[i].position,
            currentTime: 0
          });
        }
      } else {
        for (let i = 0; i < this.memory.length; i++) {
          this.memory[i].active = false;
        }
      }

      this.setEntityContent(["activePlayer", payload.status]);
      this.memory.find(item => item.position === payload.position).active =
        payload.status;
    },
    onEnded(payload) {
      for (let i = 0; i < this.memory.length; i++) {
        this.memory[i].active = false;
      }
      let mmIndex = this.memory.findIndex(
        item => item.position === payload.position
      );
      if (this.memory[mmIndex + 1]) {
        this.memory[mmIndex + 1].active = true;
      } else if (this.memory[0]) {
        this.memory[0].active = true;
      }
    }
  }
};
</script>

<style scoped>
/deep/ .audio-list-move {
  transition: transform 1s;
}
</style>
