<template>
  <div
    class="track"
    :class="{ active: active }"
    :key="item.position"
    @click="toggleActive()"
  >
    <div class="track-container">
      <div class="number">
        {{ index + 1 }}
        <img v-if="active" src="~@/assets/i/active-track.svg" alt="" />
      </div>
      <div class="name">{{ item.title }}</div>
      <div class="time">
        {{ timeString }}
      </div>
    </div>
    <div v-if="active" class="progress">
      <span :style="timeStyle"></span>
    </div>
  </div>
</template>

<script>
import utils from "@/lib/utils";
export default {
  name: "Track",
  props: ["item", "index", "active", "time"],
  computed: {
    timeString() {
      return utils.buildTimeString(this.item.durationSec, false);
    },
    timeStyle() {
      return {
        width: this.time / (this.item.durationSec / 100) + "%"
      };
    }
  },
  methods: {
    toggleActive() {
      this.$emit("active-change", {
        position: this.item.position,
        status: !this.active
      });
    }
  }
};
</script>

<style scoped></style>
